import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import ChineseLayout from "../../components/ChineseLayout"
import Accordion from "../../components/Accordion"
import AirportPlan from "../../images/static-only/visit-us/airport-plan.jpg"
import FindUs from "../../images/static-only/visit-us/find-us.jpg"
import HeaderImage from "../../images/backgrounds/mjn-stand.png"
// import Inactivity from "../../components/Inactivity"

/* Accordion Listing structure = Hero > Accordions */

class AccordionListing extends React.Component {
  componentDidMount() {
    Accordion()
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - 访问我们</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={HeaderImage} alt="" className="mjn-stand" />

            <div className="hero-area__inner">
              <h1>访问我们</h1>
              <nav className="breadcrumb" role="navigation">
                <Link to="/zh" title="首页">
                  <i className="fas fa-arrow-left"></i>
                  首页
                </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/zh" title="首页">
                <i className="fas fa-arrow-left"></i>
                首页
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">体验店</div>
              <div className="accordion__content">
                <img src={HeaderImage} alt="" className="mjn-stand" />
                <div className="rich-text">
                  <p>这个位于史基浦机场的概念店是美赞臣首家全球亲子体验店，家长和宝宝可以在这里获得前所未有的浸入式教育体验。店内选购，国际精选商品即可送货上门<sup>1</sup>，还可以参加店内活动，在现场获得独家机场折扣<sup>2</sup>和个人定制礼物<sup>3</sup>。</p>
                  <br />
                  <p className="small">1 产品供应情况因交货国家而异 。</p>
                  <p className="small">2 优惠仅限于结账时使用于部分产品。 大多数国家的优惠不包括婴儿配方奶粉和医疗食品。</p>
                  <p className="small">3 无需购买。 数量有限，视库存情况和条件而定，送完即止。  详情请询问工作人员。</p>
                </div>
              </div>
            </div>
            
            <div className="accordion">
              <div className="accordion__heading menu-button">体验店地址</div>
              <div className="accordion__content">
                <img src={FindUs} alt="A family walking in an airport terminal" />                
                <div className="rich-text">
                  <p><strong>阿姆斯特丹史基浦机场3号候机厅一层</strong></p>
                  <img src={AirportPlan} alt="Plan of the airport" />
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">史基浦机场儿童游览攻略</div>
              <div className="accordion__content">
                <img src={FindUs} alt="A family walking in an airport terminal" />
                <div className="rich-text">
                  <h3>NEMO 科学博物馆</h3>
                  <p>NEMO科学博物馆是阿姆斯特丹最适合家庭参观的地点之一。在史基浦机场，通过8个交互式装置，您和孩子可以亲手动手实验，了解科技知识。您可以使声波可见化“驯服”电流，将您的脸部与别人的脸部特征融合。展区内设有幼儿游乐场。</p>
                  <p>
                    <strong>地点:</strong>(安检后) NEMO Holland Boulevard <br />
                    <strong>开放时间:</strong> 24/7
                  </p>

                  <h3>大型毛绒玩偶</h3>
                  <p>靠近NEMO博物馆，来和超大型毛绒玩偶一起玩耍吧！</p>
                  <p>
                    <strong>地点:</strong> (安检后) Holland Boulevard, NEMO 博物馆旁 <br />
                    <strong>开放时间:</strong> 24/7
                  </p>

                  <h3>玩具世界</h3>
                  <p>玩具有助于提升宝贝创造力。在这里您可以探索来自乐高、米菲及芭比与她多彩衣橱的有趣玩具。</p>
                  <p>
                    <strong>地点:</strong> (安检后) Let’s Play Holland Boulevard<br />
                    <strong>开放时间:</strong> 5:00 - 1:30
                  </p>
                  <p>
                    <strong>地点:</strong> (安检后) Let’s Play Lounge 2<br />
                    <strong>开放时间:</strong> 5:00 - 1:30
                  </p>

                  <h3>乐高<sup>&reg;</sup> 快闪店</h3>
                  <p>重温童年乐趣，来乐高<sup>&reg;</sup> 快闪店玩耍吧！</p>
                  <p>
                    <strong>地点:</strong> (安检后) LEGO<sup>&reg;</sup> pop-up store E-pier<br />
                    <strong>开放时间:</strong> 5:00 - 1:30
                  </p>
                  <p>
                    <strong>地点:</strong> (安检前) LEGO<sup>&reg;</sup> pop-up store Plaza<br />
                    <strong>开放时间:</strong> 7:00 - 22:00
                  </p>

                </div>
              </div>
            </div>

          </section>

        </main>
      </ChineseLayout>
    )
  }
}

export default AccordionListing